import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import moment from 'moment/moment';

import { renderTextOrHtml } from 'shared/functions';

const getPerks = () => ({
  employmentType: {
    label: translate('Employment type'),
    element: ({ employmentType: { title } }) => title,
  },
  slug: {
    label: translate('Job ID (ref)'),
    element: ({ slug }) => slug,
  },
  owner: {
    label: translate('Contact name (owner)'),
    element: ({ owner: { name } }) => get(name, 'displayName'),
  },
  createdAt: {
    label: translate('Job published'),
    element: ({ createdAt }, { dateFormat }) => (createdAt ? moment(createdAt).format(dateFormat) : ''),
  },
  startDate: {
    includeAlways: true,
    label: translate('Start Date'),
    element: ({ startDate }, { dateFormat }) => (startDate ? moment(startDate).format(dateFormat) : translate('ASAP')),
  },
  workflowState: {
    label: translate('Job status'),
    element: (
      { workflowState: { name } }, // eslint-disable-line react/prop-types
    ) => <span className="badge">{name}</span>,
  },
});

class InfoAndPerks extends React.Component<any, any> {
  state = {
    perks: getPerks(),
  };

  renderPerk = (perkKey) => {
    const {
      settings: {
        dateFormats: { dateFormat },
      },
    } = this.context;
    const { job } = this.props;
    // @ts-expect-error
    const { employmentType, perks } = this.state;

    const perksOptions = {
      dateFormat,
      employmentType,
    };
    const perk = perks[perkKey];
    const perkElement = (job[perkKey] || perk.includeAlways) && perk.element(job, perksOptions);

    return perkElement ? (
      <div key={perk.label} id={perk.label} className="row margin-bottom">
        <div className="col-xs-5">
          <label className="control-label">{perk.label}</label>
        </div>
        <div className="col-xs-7">{perkElement}</div>
      </div>
    ) : null;
  };

  renderPublicJobBodyTemplate = () => {
    const { job } = this.props;

    return <div dangerouslySetInnerHTML={{ __html: job.publicJobBodyTemplate }} />;
  };

  render() {
    const { settings } = this.context;
    const { job, jobSettings } = this.props;
    const { perks } = this.state;

    const jobPermanentAvailability = job.permanentAvailability || {};
    const holidayAllowance = get(settings, 'features.candidate.workProfile.preferredWorkingHours.holidayAllowance');

    return jobSettings.features?.job?.publicJobBodyTemplate ? (
      this.renderPublicJobBodyTemplate()
    ) : (
      <div className="job-info-block" id="job-description">
        <div className="job-detail-container">
          <div className="row">
            <div className="col-sm-4 col-xs-12 pull-right" id="job-responsibilities">
              <h3>{translate('Info and perks')}</h3>
              {Object.keys(perks).map(this.renderPerk)}
              {holidayAllowance && (
                <div className="row margin-bottom" id="job-holiday-allowance">
                  <div className="col-xs-5">
                    <label className="control-label">{translate('Holiday Allowance')}</label>
                  </div>
                  <div className="col-xs-7">
                    {jobPermanentAvailability.holidayAllowance ? translate('Yes') : translate('No')}
                  </div>
                </div>
              )}
            </div>
            {job.description && (
              <div className="col-sm-8 col-xs-12">
                <h3>{translate('Job Description')}</h3>
                <div className="about-container job-description-format">{renderTextOrHtml(job.description)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
InfoAndPerks.contextTypes = {
  settings: PropTypes.object.isRequired,
};

// @ts-expect-error
InfoAndPerks.propTypes = {
  job: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default InfoAndPerks;
